import React , {useEffect , useState } from 'react'
import { ObtenerBlogs } from '../../Api/ObtenerBlogs';
import { ObtenerBlogsFilter } from '../../Api/ObtenerBlogsFilter';
import { ObtenerCategorias } from '../../Api/ObtenerCategorias';
import { ObtenerTags } from '../../Api/ObtenerTags';
import { useHistory } from 'react-router-dom';
const FilterBlog = (props) => {

    const history = useHistory();

    const [dataBlogs ,setDataBlogs] = useState([]);
    const [dataCategorias ,setDataCategorias] = useState([]);
    const [dataTags ,setDataTags] = useState([]);

    const [Data, setData] = useState({
        Busqueda:""
    });

    useEffect(()=>{
        GetAllBlogs();
    },[])

    useEffect(()=>{
        GetCategirias();
    },[])

    useEffect(()=>{
        GetTags();
    },[])

    const VerBlog = (blog,title) =>{
        let Id = blog
        history.push({
            pathname: '/blog/Ver/'+Id,
            state: { title: title }
        })
    }

    const GetAllBlogs = async () =>{
        const Blogs = await ObtenerBlogs("EGD_LEGAL");
        setDataBlogs(Blogs);
    }

    const GetCategirias = async () =>{
        const Categorias = await ObtenerCategorias("EGD_LEGAL");
        setDataCategorias(Categorias);
    }

    const GetTags = async () =>{
        const Tags = await ObtenerTags("EGD_LEGAL");
        const Array = Tags[0]["Tags"].replace(" ", "").split(",");

        let result = Array.filter((item,index)=>{
            return Array.indexOf(item) === index;
        })

        setDataTags(result);
    }

    const CATEGOIRIAS = dataCategorias.map((Data,index) =>{

        return (
            <>
                <li onClick={() => FILTRAR_CATEGORIA("Categoria" , Data.Descripcion)} key={index}><a>{Data.Descripcion}<span>({Data.Cantidad})</span></a></li>
            </>
            
        )
        
    });

    const TAGS = dataTags.map((Data,index) =>{
        return (
            <>
                <li onClick={() => FILTRAR_TAGS("Tag" , Data)} key={index}><a>{Data}</a></li>
            </>
            
        )
        
    });

    const BLOGS_RECIENTES = dataBlogs.map((Data,index) =>{

        let Imagen = Data.Host + Data.Portada;

        let FECHA = Data.FechaRestada;

        return (
            <>
            {
                index < 5 && (
                    <div key={index} className="post-item clearfix">
                        <img src={Imagen} alt={Data.Titulo}></img>
                        <h4><a onClick={()=>VerBlog(Data.Id,Data.Titulo)}>{Data.Titulo}</a></h4>
                        <time dateTime={FECHA}>{FECHA}</time>
                    </div>
                )
            }
            </>
            
        )
        
    });

    const handleChange = (e) => {
        const { name, value } = e.target;
        setData((Data) => ({
          ...Data,
          [name]: value,
        }));
    };

    const FILTRAR = async (Tipo) =>{
        if(Data.Busqueda != ""){
            const FilterBlogs = await ObtenerBlogsFilter(Tipo,Data.Busqueda,"EGD_LEGAL");
            props.FiltrarDatos(FilterBlogs,"RESETEAR");
        }else{
            props.FiltrarDatos("TODOS","NORMAL");
        }
    }

    const FILTRAR_CATEGORIA = async (Tipo,Busqueda) =>{
        const FilterBlogs = await ObtenerBlogsFilter(Tipo,Busqueda,"EGD_LEGAL");
        props.FiltrarDatos(FilterBlogs,"RESETEAR");
    }

    const FILTRAR_TAGS = async (Tipo,Busqueda) =>{
        const FilterBlogs = await ObtenerBlogsFilter(Tipo,Busqueda,"EGD_LEGAL");
        props.FiltrarDatos(FilterBlogs,"RESETEAR");
    }

    

    return (

        <div className="col-lg-4">

            <div className="sidebar">

            <h3 className="sidebar-title">Buscar</h3>
            <div className="sidebar-item search-form">
                <form action="">
                <input type="text" onChange={handleChange} value={Data.Busqueda} name="Busqueda"></input>
                <button type="button" onClick={()=>FILTRAR("Busqueda")} ><i className="bi bi-search"></i></button>
                </form>
            </div>

            <h3 className="sidebar-title">Categorías</h3>
            <div className="sidebar-item categories">
                <ul>
                    {CATEGOIRIAS}
                </ul>
            </div>

            <h3 className="sidebar-title">Blogs Recientes</h3>

            <div className="sidebar-item recent-posts">

                {BLOGS_RECIENTES}

            </div>

            <h3 className="sidebar-title">Tags</h3>
            <div className="sidebar-item tags">
                <ul>
                    {TAGS}
                </ul>
            </div>

            </div>

        </div>

    )
}

export default FilterBlog

