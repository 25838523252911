import React , {useEffect , useState } from 'react'
import { useHistory } from 'react-router-dom';
import '../../assets/css/style.css';
import { Steps } from 'antd';
import { ConsoleSqlOutlined } from '@ant-design/icons';
import '../../assets/sass/main.scss';
import ModalVideo from 'react-modal-video'
const { Step } = Steps;

const HomePage = () => {
    
    const history = useHistory();
    const [isOpen, setOpen] = useState(false)
    const [data ,setData] = useState([]);

    useEffect(()=>{
       
    },[])



    const VerBlog = (blog,title) =>{
        let Id = blog
        history.push({
            pathname: '/blog/Ver/'+Id,
            state: { title: title }
        })
    }

    const RetornarFecha = (fecha) => {

        const createReview = new Date(fecha);
        let Fecha =
          (createReview.getDate() < 10 ? "0" : "") +
          createReview.getDate() +
          "-" +
          (createReview.getMonth() + 1 < 10 ? "0" : "") +
          (createReview.getMonth() + 1) +
          "-" +
          createReview.getFullYear();
        return Fecha;

    };

    const BLOGS = data.map((Estados,index) =>{

        let CONTENIDO =  Estados['content:encoded'];
        let Imagen = CONTENIDO.split('<img')
        Imagen = Imagen[1].split('src')
        Imagen = Imagen[1].split('"')[1]

        console.log(Imagen) 

        let FECHA = RetornarFecha(Estados.pubDate);


        return (
            <div key={index} className={`col-lg-4 col-md-6 portfolio-item ${Estados.categories[0]}`}>
                <div className="portfolio-wrap">
                    <img src={Imagen} className="img-fluid" alt=""></img>
                    <div className="portfolio-info">
                        <h4>{Estados.title}</h4>
                        <p>compañias_ec</p>
                        <p>{FECHA}</p>
                        <div className="portfolio-links">
                            {/* <a href={Imagen} data-type="image" data-gallery="portfolioGallery" className="portfolio-lightbox" title="¿Por qué elegirnosc EAE?"><i className="bx bx-search"></i></a> */}
                            <a onClick={()=>VerBlog(index,Estados.title)} title="Ver Blog"><i className='bx bx-fullscreen'></i></a>
                        </div>
                    </div>
                </div>
            </div>
        )
        
    });

    

    return (
        <>

            <section id="hero" className="d-flex flex-column justify-content-center align-items-center">
                <div className="container" data-aos="fade-in">
                    <h1>Bienvenido</h1>
                    <h2>Tu estudio jurídico EGD Legal</h2>
                    <div className="d-flex align-items-center">
                        <i className="bx bxs-right-arrow-alt get-started-icon"></i>
                        <a href="#about" className="btn-get-started scrollto">Próximamente</a>
                    </div>
                </div>
            </section>

            <main id="main">

                <section id="why-us" className="why-us">
                    <div className="container">

                        <div className="row">
                        <div  className="col-xl-4 col-lg-5" data-aos="fade-up">
                            <div  id="Inicial"className="content">
                            <h3>Por qué elegir EGD Legal para tus tramites?</h3>
                            <p>
                               {/* Por que somos los mejores del mercado , te cobramos barato y te libramos de cana. Para mas infromación presiona el botón de aqui abajo, el paquete contiene jueces vendidos y policias corruptos. */}
                            </p>
                            <div className="text-center">
                                <a href="#about" className="more-btn">Leer mas <i className="bx bx-chevron-right"></i></a>
                            </div>
                            </div>
                        </div>
                        <div className="col-xl-8 col-lg-7 d-flex">
                            <div className="icon-boxes d-flex flex-column justify-content-center">
                            <div className="row">
                                <div className="col-xl-4 d-flex align-items-stretch" data-aos="fade-up" data-aos-delay="100">
                                <div className="icon-box mt-4 mt-xl-0">
                                    <i className="bx bx-receipt"></i>
                                    <h4>Compromiso total</h4>
                                    {/* <p>Nos comprometemos a defenderte incluso en las peores condiciones, no te preocupes de nada deja todo en nustras manos</p> */}
                                </div>
                                </div>
                                <div className="col-xl-4 d-flex align-items-stretch" data-aos="fade-up" data-aos-delay="200">
                                <div className="icon-box mt-4 mt-xl-0">
                                    <i className="bx bx-cube-alt"></i>
                                    <h4>Personal altamente capacitado</h4>
                                    {/* <p>Tenemso experiancia a lo largo de los años hemos puesto en libertad a mas de 50 000 presos</p> */}
                                </div>
                                </div>
                                <div className="col-xl-4 d-flex align-items-stretch" data-aos="fade-up" data-aos-delay="300">
                                <div className="icon-box mt-4 mt-xl-0">
                                    <i className="bx bx-images"></i>
                                    <h4>Extenso historial de proyectos</h4>
                                    {/* <p>Hemos realizado proyectos en la Peni , la Regional y la Roca</p> */}
                                </div>
                                </div>
                            </div>
                            </div>
                        </div>
                        </div>

                    </div>
                </section>

                <section id="about" className="about section-bg">
                    <div className="container">

                        <div className="row">
                        <div className="col-xl-5 col-lg-6 video-box d-flex justify-content-center align-items-stretch position-relative" data-aos="fade-right">
                            <a onClick={()=> setOpen(true)} className="play-btn mb-4"></a>
                        </div>

                        <div className="col-xl-7 col-lg-6 icon-boxes d-flex flex-column align-items-stretch justify-content-center py-5 px-lg-5">
                            <h4 data-aos="fade-up">Información</h4>
                            <h3 data-aos="fade-up">Somos EGD Legal y asociados una empresa constituida con más de 20 años de experiencia</h3>
                            <p data-aos="fade-up">Constituimos compañias y enseñamos a nuestros estudiantes a hacerlo de una manera facil y rapida con una seria de caracteristicas y metodos de aprendizaje que llevaran su vida laborar y personal al siguiente nivel</p>

                            <div className="icon-box" data-aos="fade-up">
                            <div className="icon"><i className="bx bx-fingerprint"></i></div>
                            <h4 className="title"><a >Asesoria en línea</a></h4>
                            <p className="description">Asesorias 100% online , reserva tu cita de forma automática mediante nuestro sistema de citas integrado</p>
                            </div>

                            <div className="icon-box" data-aos="fade-up" data-aos-delay="100">
                            <div className="icon"><i className="bx bx-gift"></i></div>
                            <h4 className="title"><a >Premios</a></h4>
                            <p className="description">Realizamos eventos gratuitos donde estaremos premiando a los estudiantes mas activos en el curso</p>
                            </div>

                            <div className="icon-box" data-aos="fade-up" data-aos-delay="200">
                            <div className="icon"><i className="bx bx-atom"></i></div>
                            <h4 className="title"><a >Crecimiento</a></h4>
                            <p className="description">Ofrecemos nuestro amplio conocimiento en temas legales para fortalecer y ayudar a nuestros estudiantes a estar preparados para cualquier situación</p>
                            </div>

                        </div>
                        </div>

                    </div>
                </section>


                <section id="contact" className="contact">
                    <div className="container">

                        <div className="section-title">
                        <h2 data-aos="fade-up">Contactanos</h2>
                        {/* <p data-aos="fade-up">Magnam dolores commodi suscipit. Necessitatibus eius consequatur ex aliquid fuga eum quidem. Sit sint consectetur velit. Quisquam quos quisquam cupiditate. Et nemo qui impedit suscipit alias ea. Quia fugiat sit in iste officiis commodi quidem hic quas.</p> */}
                        </div>

                        <div className="row justify-content-center">

                        <div className="col-xl-3 col-lg-4 mt-4" data-aos="fade-up">
                            <div className="info-box">
                            <i className="bx bx-map"></i>
                            <h3>Dirección</h3>
                            <p>Guayaquil, Guayas-Ec</p>
                            </div>
                        </div>

                        <div className="col-xl-3 col-lg-4 mt-4" data-aos="fade-up" data-aos-delay="100">
                            <div className="info-box">
                            <i className="bx bx-envelope"></i>
                            <h3>Correo</h3>
                            <p><a style={{color: '#313030'}} href="mailto:egavilanezd@egdlegal.com"> egavilanezd@egdlegal.com</a></p>
                            </div>
                        </div>
                        <div className="col-xl-3 col-lg-4 mt-4" data-aos="fade-up" data-aos-delay="200">
                            <div className="info-box">
                            <i className="bx bx-phone-call"></i>
                            <h3>Llamanos a</h3>
                            <p><a style={{color: '#313030'}} href="tel:+593999459124"> +593 999 459 124</a></p>
                            </div>
                        </div>
                        </div>

                        {/* <div className="row justify-content-center" data-aos="fade-up" data-aos-delay="300">
                        <div className="col-xl-9 col-lg-12 mt-4">
                            <form action="forms/contact.php" method="post" role="form" className="php-email-form">
                            <div className="row">
                                <div className="col-md-6 form-group">
                                <input type="text" name="name" className="form-control" id="name" placeholder="Su Nombre" required></input>
                                </div>
                                <div className="col-md-6 form-group mt-3 mt-md-0">
                                <input type="email" className="form-control" name="email" id="email" placeholder="Su Correo" required></input>
                                </div>
                            </div>
                            <div className="form-group mt-3">
                                <input type="text" className="form-control" name="subject" id="subject" placeholder="Asunto" required></input>
                            </div>
                            <div className="form-group mt-3">
                                <textarea className="form-control" name="message" rows="5" placeholder="Mensaje" required></textarea>
                            </div>
                            <div className="my-3">
                                <div className="loading">Cargando</div>
                                <div className="error-message"></div>
                                <div className="sent-message">Su mensaje fue enviado. Muchas gracias!</div>
                            </div>
                            <div className="text-center"><button type="submit">Enviar Mensaje</button></div>
                            </form>
                        </div>

                        </div> */}

                    </div>
                </section>
            </main>

            {/* MODAL VIDEO */}
            <ModalVideo channel='youtube' autoplay isOpen={isOpen} videoId="N9bcW5qi9Y3lPIG4" onClose={() => setOpen(false)} />
        </>
    )
}

export default HomePage
