import React from 'react'
import { useHistory, useLocation } from 'react-router-dom';
import useAuth from '../../Auth/useAuth'
import { makeStyles } from '@material-ui/core/styles';
import {ObtenerUsuario} from '../../Api/ObtenerUsuario';
import { Form, Input, Button, Checkbox, message ,Spin } from 'antd';
import 'antd/dist/antd.css';
import { UserOutlined, LockOutlined ,LoadingOutlined} from '@ant-design/icons';
import Imagen from '../../assets/img/portada-egd-legal.jpg'
var md5 = require("md5"); 
const LoginPage = () => {
    const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;
    const style = useStyles();
    const location = useLocation();
    const history = useHistory();
    const auth = useAuth();
    const PreviusUrl = location.state?.from;
    const [StateLoading, setStateLoading] = React.useState(false);

    const [State, setState] = React.useState({
        Usuario: "",
        Clave:"",
    });

    const handleLogin = async () =>{

        if(ValidarFormulario()){

            setStateLoading(true);
            const Usuario = await ObtenerUsuario(State.Usuario,md5(State.Clave));
            console.log(Usuario)
            if(Usuario && Usuario.length > 0 ){
                auth.login(Usuario[0]);
                console.log(Usuario[0])
                history.push("/administracion")
            }else{
                message.error('Usuario o contraseña incorrecta');
                setStateLoading(false);
            }
        }
    }

    const ObtenerUsuarios = (e) =>{
        const Usuario = e.target.value
        setState({
            ...State,
            Usuario: Usuario,
        });
    }

    const ObtenerClave = (e) =>{
        const Clave = e.target.value
        setState({
            ...State,
            Clave: Clave,
        });
    }

    const ValidarFormulario = () =>{
        if(
            ValidarUsuario() &&
            ValidarClave()
        ){
            return true
        }
        return false
    }

    const ValidarUsuario = () =>{
        if(State.Usuario ==='' || State.Usuario ===null){
            message.error('Ingrese su usuario');
            return false
        }
        return true
    }

    const ValidarClave = () =>{
        if(State.Clave ==='' || State.Clave ===null){
            message.error('Ingrese su contraseña');
            return false
        }
        return true
    }

    return (

        <div className="Login-access">
            <Form
                name="normal_login"
                className={style.loginForm}
                initialValues={{
                    remember: true,
                }}
            >
                <Form.Item
                    name="username"
                >
                    <Input 
                        prefix={<UserOutlined />} 
                        placeholder="Usuario" 
                        onChange={(e)=>ObtenerUsuarios(e)}
                    />
                </Form.Item>

                <Form.Item
                    name="password"
                >
                    <Input
                        prefix={<LockOutlined/>}
                        type="password"
                        placeholder="Contraseña"
                        onChange={(e)=>ObtenerClave(e)}
                    />
                </Form.Item>

                <Form.Item>
                    <Form.Item name="remember" valuePropName="checked" noStyle>
                        <Checkbox style={{color:'white'}}>Recordar contraseña</Checkbox>
                    </Form.Item>
        
                    <p className={style.Forgot}>
                        EGD | Legal 
                    </p>
                </Form.Item>

                <Form.Item>
                    <Button type="primary" onClick={handleLogin} className={style.LoginButton}>
                        Entrar
                    </Button>
                </Form.Item>

                {   
                    StateLoading === true && (
                        <div className={style.Cargar}>
                            <Spin indicator={antIcon} />
                        </div>
                    )
                }
            </Form>
        </div>
    )
}

export default LoginPage

const useStyles = makeStyles((theme) => ({

    loginForm:{
        width: '300px',
        maxWidth: '300px'
    },
    Forgot:{
        float: 'right',
        fontSize:'10px',
        color:'white'
    },
    LoginButton:{
        width:'100%'
    },
    Cargar:{
        justifyContent:'center',
        textAlign:'center'
    },

}));