import React , {useEffect , useState } from 'react'
import { ObtenerBlogs } from '../../Api/ObtenerBlogs';
import { Empty  } from 'antd';
import FilterBlog from '../BlogPage/FilterBlog';
import DataPaginada from '../BlogPage/DataPaginada';

const BlogPage = (props) => {

    const [dataBlogs ,setDataBlogs] = useState([]);

    const [itemOffset, setItemOffset] = useState("");

    useEffect(()=>{
       
        if(props.location.state?.Blogs){
            
            console.log("ENVIADO POR PROPS")
            console.log(props.location.state.Blogs)
            setDataBlogs(props.location.state.Blogs);
        }else{
            GetBlogs();
           
        }
        
    },[])

    const GetBlogs = async () =>{
        const Blogs = await ObtenerBlogs("EGD_LEGAL")
        setDataBlogs(Blogs);
    }
    


    const FiltrarDatos = (Blogs,Opcion) =>{
        if(Blogs==="TODOS"){
            GetBlogs();
        }else{
            if(Opcion="RESETEAR"){
                setItemOffset("SI");
            }
            setDataBlogs(Blogs);
        }
        
    }

    return (
        <>

            <section id="blog" className="blog">
                <div className="container" data-aos="fade-up">

                    <div className="row">

                    <div className="col-lg-8 entries">

                    {
                        dataBlogs.length > 0 ?
                            <DataPaginada data = { dataBlogs } Offset = {itemOffset} />
                            :
                            <Empty description={"No hay blogs que mostrar"}/>
                    }
    
                        
                    </div>

                    <FilterBlog
                         FiltrarDatos = {(e,opcion)=>FiltrarDatos(e,opcion)} 
                    />

                    </div>

                </div>
            </section>
        </>
    )
}

export default BlogPage
