import React , {useEffect , useState } from 'react'
import { ObtenerBlogsId } from '../../Api/ObtenerBlogsId';
import FilterBlog from '../BlogPage/FilterBlog';
import { useParams , useHistory } from 'react-router-dom';
const DetalleBlog = () => {

    const { id } = useParams();
    const history = useHistory();
    const [data ,setData] = useState([]);
    const [title ,setTitle] = useState([]);
    const [nombre ,setNombre] = useState([]);
    const [perfil ,setPerfil] = useState([]);
    const [descripcion ,setDescripcion] = useState([]);


    useEffect(()=>{
        GetBlogs(id);
        window.scrollTo(0,0);
    },[id])


    const GetBlogs = async (id) =>{
        const Blog = await ObtenerBlogsId(id,"EGD_LEGAL")
        setData(Blog.Contenido);
        setTitle(Blog.Titulo)
        setNombre(Blog.NombreAutor)
        setPerfil(Blog.FotoAutor);
        setDescripcion(Blog.DescripcionAutor)
    }

    const FiltrarDatos = (Blogs) =>{
        if(Blogs==="TODOS"){
            history.push("/blogs");
        }else{
            console.log(Blogs)
            history.push("/blogs", { Blogs: Blogs });
        }
        
    }

    return (
        <div >
            {/* <section id="hero-blog" className="d-flex align-items-center justify-content-center breadcrumbs">
                <div className="container">

                    <ol>
                    <li><a href="">Blog</a></li>
                    <li>{title}</li>
                    </ol>
                    <h2 style={{color:'white'}}>{title}</h2>

                </div>
            </section> */}

            <section id="blog" className="blog">
                <div className="container" data-aos="fade-up">

                    <div className="row">

                    <div className="col-lg-8 entries">
                        <div style={{padding:'100px 0 0' , width:'90%' , height: 'auto' , wordWrap: 'break-word' , margin:'0 auto'}}>
                            <h1 id='BlogTitle'>{title}</h1>
                            <div id='Blog' dangerouslySetInnerHTML={ { __html: data } }></div>
                        </div>
                        <div className="blog-author d-flex align-items-center">
                            <img style={{width: '60px', marginTop:'-35px'}} src={perfil} className="rounded-circle float-left" alt=""></img>
                            <div>
                                <h4>{nombre}</h4>
                                <div className="social-links">
                                <a href="https://twitters.com/#"><i className="bi bi-twitter"></i></a>
                                <a href="https://facebook.com/#"><i className="bi bi-facebook"></i></a>
                                <a href="https://instagram.com/#"><i className="biu bi-instagram"></i></a>
                                </div>
                                <p>{descripcion}</p>
                            </div>
                        </div>
                    </div>

                    <FilterBlog
                         FiltrarDatos = {(e)=>FiltrarDatos(e)} 
                    />

                    </div>

                </div>
            </section>
        </div>
    )
}

export default DetalleBlog

