import hostCompaniasEc from '../Util/hostCompaniasEc';
async function ObtenerBlogsFilter(Tipo,Busqueda,Web){
    const UrlApi = hostCompaniasEc+'/API_COMPANIAS/procedures/blog/search.php?token=015ec0d618fe3fa9c7d0a1abda2b159c&Tipo='+Tipo+'&Busqueda='+Busqueda+'&Web='+Web;
        try{
            let response= await fetch(UrlApi);
            let responseJson= await response.json();
            let res= await responseJson.body;
            return res;
        }catch(error){
            console.error(`ERROR BLOGS FILTRO ES :  ${error}`)
        }
}
export  {ObtenerBlogsFilter};