import React from 'react'
import { Button, Result } from 'antd';
import {  useHistory  } from 'react-router-dom';
const Error404 = () => {
    const history = useHistory();
    const Navegar = () =>{
        history.push("/");
    }
    return (
        <Result
            status="404"
            title="404"
            subTitle="Lo sentimos, la pagina que visitaste no existe."
            extra={<Button onClick={()=>Navegar()} type="primary">Inicio</Button>}
        />
    )
}

export default Error404
