import hostCompaniasEc from '../Util/hostCompaniasEc';
async function ObtenerCategorias(Web){
    const UrlApi = hostCompaniasEc+'/API_COMPANIAS/procedures/categories/read.php?token=015ec0d618fe3fa9c7d0a1abda2b159c&Web='+Web;
        try{
            let response= await fetch(UrlApi);
            let responseJson= await response.json();
            let res= await responseJson.body;
            return res;
        }catch(error){
            console.error(`ERROR CATEGORIAS ES :  ${error}`)
        }
}
export  {ObtenerCategorias};