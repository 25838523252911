import React from 'react'
import '../../assets/css/style.css';
const Footer = () => {

    return (
        <>
            <footer id="footer">

            <div className="footer-top">
                    <div className="container">
                        <div className="row">

                        <div className="col-lg-3 col-md-6 footer-contact">
                            <h3>EGD Legal</h3>
                            <p>
                                Guayas <br></br>
                                Guayaquil - Ec<br></br>
                                <br></br>
                                <strong>Teléfono:</strong><a style={{color: '#313030'}} href="tel:+593999459124"> +593 999 459 124</a><br></br>
                                <strong>Correo:</strong><a style={{color: '#313030'}} href="mailto:egavilanezd@egdlegal.com"> egavilanezd@egdlegal.com</a><br></br>
                            </p>
                        </div>

                        <div className="col-lg-2 col-md-6 footer-links">
                            <h4>Links utiles</h4>
                            <ul>
                            <li><i className="bx bx-chevron-right"></i> <a href="#hero">Inicio</a></li>
                            <li><i className="bx bx-chevron-right"></i> <a href="#about">Información</a></li>
                            {/* <li><i className="bx bx-chevron-right"></i> <a href="#">Servicios</a></li> */}
                            <li><i className="bx bx-chevron-right"></i> <a>Terminos de uso</a></li>
                            <li><i className="bx bx-chevron-right"></i> <a>Politicas de privacidad</a></li>
                            </ul>
                        </div>

                        <div className="col-lg-3 col-md-6 footer-links">
                            <h4>Otros servicios</h4>
                            <ul>
                            {/* <li><i className="bx bx-chevron-right"></i> <a href="#">Diseño web</a></li> */}
                            <li><i className="bx bx-chevron-right"></i> <a>Inversiones</a></li>
                            {/* <li><i className="bx bx-chevron-right"></i> <a href="#">Gestión de proyectos</a></li> */}
                            {/* <li><i className="bx bx-chevron-right"></i> <a href="#">Marketing</a></li> */}
                            </ul>
                        </div>

                        <div className="col-lg-4 col-md-6 footer-newsletter">
                            <h4>Subscríbete</h4>
                            <p>Recibe correos con información relevante acerca de los temas tratados en este curso</p>
                            {/* <form action="" method="post">
                                <input type="email" name="email"></input><input type="submit" value="Subscribe"></input>
                            </form> */}
                        </div>

                        </div>
                    </div>
                </div>

                <div className="container d-lg-flex py-4">

                    <div className="me-lg-auto text-center text-lg-start">
                        <div className="copyright">
                            &copy; Copyright <strong><span>EGD Legal</span></strong>. Todos los derechos reservados
                        </div>
                        <div className="credits">
                            Desarrollado por <a style={{color:'#ff7e54'}} target="_blank" href="https://www.linkedin.com/in/sebastian-alvarado-a01a4a195">Sebastian Alvarado</a>
                        </div>
                    </div>
                    <div className="social-links text-center text-lg-right pt-3 pt-lg-0">
                        <a href="#hero" className="twitter"><i className="bx bxl-twitter"></i></a>
                        <a href="#hero" className="facebook"><i className="bx bxl-facebook"></i></a>
                        <a href="#hero" className="instagram"><i className="bx bxl-instagram"></i></a>
                        <a href="#hero" className="google-plus"><i className="bx bxl-skype"></i></a>
                        <a href="#hero" className="linkedin"><i className="bx bxl-linkedin"></i></a>
                    </div>
                </div>
            </footer>
            <div id="preloader"></div>
            <a onClick={()=>window.scrollTo(0,0)} className="back-to-top d-flex align-items-center justify-content-center"><i className="bi bi-arrow-up-short" style={{color:'white'}}></i></a>
        </>
    )
}

export default Footer

