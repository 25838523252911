import React, { useState, useEffect } from "react";
import { ObtenerPodcast } from '../../Api/ObtenerPodCast'
const PodcastPage = () => {
    const [loading, setLoading] = useState(false);
    const [data, setData] = useState([]);
    useEffect(() => {
        GetServicios();
        setLoading(true);
    }, []);

    const GetServicios = async () => {
        const Datos = await ObtenerPodcast();
        console.log(Datos)
        setData(Datos);
        setLoading(false);
    };

    const PODCAST = data.map((Data,index) => {
        return (
            <div key={index} className="col-lg-4 col-md-6" data-aos="fade-up">
                <div className="icon-box podcast">
                    <div className="icon"><i className="bi bi-youtube"></i></div>
                    <h4 className="title"><a href="">{Data.Titulo}</a></h4>
                    <div dangerouslySetInnerHTML={{__html: Data.Url}}></div>
                    <p className="description">{Data.Descripcion.substring(0, 100)}</p>
                </div>
            </div>
        )
    } 
        
    );

    return (
        <div >
            {/* <h1>POD CAST</h1> */}
            <section id="services" className="services section-bg">
                <div className="container">
                    <div className="row">
                        {PODCAST}
                    </div>
                </div>
                
            </section>
            
        </div>
    )
}

export default PodcastPage
