import React , {useEffect , useState } from 'react'

import '../../assets/css/style.css';
import useAuth from '../../Auth/useAuth';
import { useLocation , useHistory, NavLink  } from 'react-router-dom';
import Imagen from '../../assets/img/Logo.png';
const Navbar = () => {
    const location = useLocation();
    const history = useHistory();
    const auth = useAuth();
    console.log(location.pathname);

    const [iconClass, setIconClass] = useState('bi-list');
    const [navbarMobile, setNavbarMobile] = useState(false);

    const toggleNavbar = () => {
        console.log("navbarMobile",navbarMobile)
        setNavbarMobile(!navbarMobile);
        setIconClass(navbarMobile ? 'bi-list' : 'bi-x');
    };


    const Navegar = (pad,type,index) =>{
        console.log("pad",pad)

        let navbar = select('#navbar')
        if (navbar.classList.contains('navbar-mobile')) {
            toggleNavbar();
        }

        let navbarlinks = select('#navbar .scrollto', true)

        // Quita la clase "active" de todos los elementos
        navbarlinks.forEach(link => {
            link.classList.remove('active');
        });

        // Aquí decides cuál enlace debe tener la clase "active"
        const indiceDelEnlaceActivo = index; // Por ejemplo, si deseas que el tercer enlace tenga la clase "active"

        // Agrega la clase "active" solo al enlace deseado
        if (navbarlinks[indiceDelEnlaceActivo]) {
        navbarlinks[indiceDelEnlaceActivo].classList.add('active');
        }
          


        if(location.pathname!=='/' || type ==='router'){
            history.push("/"+pad);
        }
    }

    const select = (el, all = false) => {
        el = el.trim()
        if (all) {
          return [...document.querySelectorAll(el)]
        } else {
          return document.querySelector(el)
        }
      }

    const Click = (index) =>{
        let navbar = select('#navbar')
        if (navbar.classList.contains('navbar-mobile')) {
            toggleNavbar();
        }


        let navbarlinks = select('#navbar .scrollto', true)

        // Quita la clase "active" de todos los elementos
        navbarlinks.forEach(link => {
            link.classList.remove('active');
        });

        // Aquí decides cuál enlace debe tener la clase "active"
        const indiceDelEnlaceActivo = index; // Por ejemplo, si deseas que el tercer enlace tenga la clase "active"

        // Agrega la clase "active" solo al enlace deseado
        if (navbarlinks[indiceDelEnlaceActivo]) {
        navbarlinks[indiceDelEnlaceActivo].classList.add('active');
        }

        setTimeout(() => {
            window.scrollTo(0, 0);
        }, 0);
    }

    return (
        <>
        

            <section id="topbar" className="d-flex align-items-center">
                <div className="container d-flex justify-content-center justify-content-md-between">
                <div className="contact-info d-flex align-items-center">
                    <i style={{color:'white'}} className="bi bi-envelope d-flex align-items-center"><a href="mailto:egavilanezd@egdlegal.com">egavilanezd@egdlegal.com</a></i>
                    <i style={{color:'white'}} className="bi bi-phone d-flex align-items-center ms-4"><a href="tel:+593999459124">+593 999 459 124</a></i>
                </div>
                </div>
            </section>

            <header id="header" className="d-flex align-items-center ">
                <div className="container d-flex align-items-center justify-content-between">

                    <div className="logo">
                        {/* <h1><a href="#">Flexor</a></h1> */}
                        <NavLink to="/">
                            <img  onClick={()=>window.scrollTo(0,0)}  src={Imagen} width="50px" alt="" ></img>
                        </NavLink>
                        
                    </div>

                    

                    <nav id="navbar" className={`navbar order-last order-lg-0 ${navbarMobile ? 'navbar-mobile' : ''}`}>

                        <ul>
                            <>
                                {
                                    auth.isLogged() && location.pathname == '/administracion' ?
                                        <>

                                            <li onClick={()=>Click()}><NavLink className="nav-link scrollto active" to="/administracion">Publicaciones</NavLink></li>
                                            <li  onClick={auth.logout}><a className="nav-link scrollto active" style={{color:'red'}}>Cerrar sesión</a></li>
                                        </>

                                        :
                                        <>
                                            <li onClick={()=>Navegar('#hero','scroll',0)}><a className="nav-link scrollto " href="#hero">Inicio</a></li>
                                            <li onClick={()=>Navegar('#about','scroll',1)}><a className="nav-link scrollto " href="#about">Información</a></li>
                                            <li onClick={()=>Click(2)}><NavLink className="nav-link scrollto " to="/blogs">Blogs</NavLink></li>
                                            <li onClick={()=>Navegar('#contact','scroll',3)}><a className="nav-link scrollto" href="#contact">Contacto</a></li>
                                        </>
                                } 
                                {
                                    auth.isLogged() && location.pathname != '/administracion' && (
                                        <li onClick={()=>Navegar('administracion','router',4)}><a>Administración</a></li>
                                    )
                                } 
                            </>
                        </ul>
                        <i onClick={toggleNavbar} className={`bi mobile-nav-toggle ${iconClass}`} ></i>
                    </nav>

                </div>
            </header>
        </>
    )
}

export default Navbar

