/* eslint-disable */
import React, { useState, useEffect } from "react";
import host from '../../Util/host';
import { makeStyles } from "@material-ui/core/styles";
import { ObtenerPodcast } from '../../Api/ObtenerPodCast'
import {
  Table,
  Button,
  Modal,
  Input,
  Form,
  Popconfirm,
  message,
  Select,
  List,
} from "antd";

const { TextArea } = Input;

import { PlusOutlined } from "@ant-design/icons";
const { Item } = Form;
const layout = {
  labelCol: {
    span: 6,
  },
  wrapperCol: {
    span: 20,
  },
};
const pagination = {
  pageSize: 5,
};
const { Option } = Select;

const ServiciosPage = () => {
  const styles = useStyles();
  const [data, setData] = useState([]);
  const [value, setValue] = useState("");
  const [Doctor, setDoctor] = useState([]);
  const [modal, setModal] = useState(false);
  const [LabelModal, setLabelModal] = useState("");
  const [disable, setDisable] = useState(false);

  const [loading, setLoading] = useState(false);
  const AbrirModal = () => {
    setModal(!modal);
  };
  const Servicios = {
    Id: "",
    Titulo: "",
    Url: "",
    Descripcion: ""
  };

  const [usuario, setUsuario] = useState(Servicios);

  useEffect(() => {
    GetServicios();
    setLoading(true);
  }, []);


  const ValidarEdicionUsuario = () => {
    if (
        ValidarTitulo() &&
        ValidarDescripcion() &&
        ValidarUrl()
    ) {
      return true;
    }
    return false;
  };

  const ValidarTitulo = () => {
    if (usuario.Titulo === "" || usuario.Titulo === null) {
      message.warning("Ingrese el título");
      return false;
    }
    return true;
  };

  const ValidarDescripcion = () => {
    if (usuario.Descripcion === "" || usuario.Descripcion === null) {
      message.warning("Ingrese la descripción");
      return false;
    }
    return true;
  };

  const ValidarUrl = () => {
    if (usuario.Url === "" || usuario.Url === null) {
      message.warning("Ingrese la url");
      return false;
    }
    return true;
  };

  
  /*     *****************       */

  const GetServicios = async () => {

    const Datos = await ObtenerPodcast() 
    setData(Datos);
    setLoading(false);
  };


  const RetornarFecha = (fecha) => {
    const createReview = new Date(fecha.seconds * 1000);
    let Fecha =
      createReview.getDate() +
      "-" +
      /* (createReview.getMonth() + 1) + */
      ((createReview.getMonth() +  1) < 10 ? "0" : "" )  +
      (createReview.getMonth() +  1) +
      "-" +
      createReview.getFullYear() +
      " " +
      createReview.getHours() +
      ":" +
      (createReview.getMinutes() < 10 ? "0" : "") +
      createReview.getMinutes();
    return Fecha;
  };

  const ControlModales = (data, name) => {
    setUsuario(data);
    setLabelModal(name);
    AbrirModal();
    if (name === "Editar") {
      setDisable(true);
    } else {
      setDisable(false);
    }
  };
  const ControlOperaciones = (name) => {
    const OPCION_OPERACIONES = {
      Ingresar: () => {
        CrearUsuarios();
      },
      Editar: () => {
        EditarUsuarios();
      },
    };
    OPCION_OPERACIONES[name]();
  };

  const columns = [
    {
      title: "Id",
      dataIndex: "Id",
      key: "Id",
      //fixed: "left",
      // ...getColumnSearchProps('username','Nombre'),
      width: 50,
    },
    {
        title: "Fecha Publicación",
        dataIndex: "FechaCreacion",
        key: "FechaCreacion",
        //fixed: "left",
        // ...getColumnSearchProps('username','Nombre'),
        width: 120,
      },
    {
      title: "Título",
      dataIndex: "Titulo",
      key: "Titulo",
      width: 150,
    },
    {
      title: "Descripción",
      dataIndex: "Descripcion",
      key: "Descripcion",
      width: 400,
    },
    // {
    //     title: "Url",
    //     dataIndex: "Url",
    //     key: "Url",
    //     width: 400,
    //   },
    {
      title: "Acciones",
      key: "acciones",
      fixed: "none",
      render: (fila) => (
        <>
          <Button type="primary" onClick={() => ControlModales(fila, "Editar")}>
            Editar
          </Button>{" "}
          <Popconfirm
            placement="topRight"
            title={"¿Desea eliminar este servicio médico?"}
            onConfirm={() => confirm(fila)}
            okText="Si"
            cancelText="No"
          >
            <Button type="primary" danger>
              Eliminar
            </Button>
          </Popconfirm>
        </>
      ),
      width: 180,
    },
  ];

  const CrearUsuarios = async () => {
    if (ValidarEdicionUsuario()) {
        let JsonGuardar = {
            token:"015ec0d618fe3fa9c7d0a1abda2b159c",
            Titulo:usuario.Titulo,
            Descripcion:usuario.Descripcion,
            Url:usuario.Url,
        }

        fetch(host+'/API_EGD_LEGAL/procedures/podcast/create.php',{
            method:'POST',
            headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
            },    
            body: JSON.stringify(JsonGuardar)                      
        }).then(response => response.json())
        .then((responseJson)=>{

            if(responseJson.status==="OK"){
                message.success(responseJson.message);
                setModal(false)
                GetServicios();
            }else{
                message.error(responseJson.message);
            }
            
        })
        .catch(function(error) {
            console.error(error);             
        });
      
    }
  };

  const confirm = (dato) => {
    EliminarPost(dato.Id);
    console.log(dato.Id)
  };

  const EliminarPost = (Id) =>{
    let JsonGuardar = {
        token:"015ec0d618fe3fa9c7d0a1abda2b159c",
        Id:Id
    }
    fetch(host+'/API_EGD_LEGAL/procedures/podcast/delete.php',{
            method:'POST',
            headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
            },    
            body: JSON.stringify(JsonGuardar)                      
        }).then(response => response.json())
        .then((responseJson)=>{

            if(responseJson.status==="OK"){
                message.success(responseJson.message);
                setModal(false)
                GetServicios();
            }else{
                message.error(responseJson.message);
            }
            
        })
        .catch(function(error) {
            console.error(error);             
        });
  }

  const EditarUsuarios = async () => {
    if (ValidarEdicionUsuario()) {
        let JsonGuardar = {
            token:"015ec0d618fe3fa9c7d0a1abda2b159c",
            Id:usuario.Id,
            Titulo:usuario.Titulo,
            Descripcion:usuario.Descripcion,
            Url:usuario.Url,
        }

        fetch(host+'/API_EGD_LEGAL/procedures/podcast/update.php',{
            method:'POST',
            headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
            },    
            body: JSON.stringify(JsonGuardar)                      
        }).then(response => response.json())
        .then((responseJson)=>{

            if(responseJson.status==="OK"){
                message.success(responseJson.message);
                setModal(false)
                GetServicios();
            }else{
                message.error(responseJson.message);
            }
            
        })
        .catch(function(error) {
            console.error(error);             
        });
      
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setUsuario((usuario) => ({
      ...usuario,
      [name]: value,
    }));
  };

  const handleChangeUrl = (e) => {
    const { name, value } = e.target;
    console.log(value)
    setUsuario((usuario) => ({
      ...usuario,
      [name]: value,
    }));
  };

  const uploadButton = (
    <div>
      {<PlusOutlined />}
      <div style={{ marginTop: 8 }}>Foto</div>
    </div>
  );

  return (
    <div className={styles.Contenedor}>
      <h1
        style={{ fontWeight: "bold", marginTop: "20px", marginBottom: "20px", whiteSpace:"nowrap"  }}
      >
        EGD | Publicaciones
      </h1>
      <Button
        className={styles.BtnIngresar}
        type="primary"
        onClick={() => ControlModales(Servicios, "Ingresar")}
      >
        Ingresar
      </Button>
      <Table
        className={styles.table}
        loading={loading}
        columns={columns}
        key={data.key}
        dataSource={data}
        pagination={pagination}
        scroll={{ y: 350, x: 900 }}
        // size="small"
      />

      <Modal
        visible={modal}
        title={LabelModal + " Podcast"}
        destroyOnClose={true}
        onCancel={AbrirModal}
        centered
        style={{width:'90%'}}
        footer={[
          <Button onClick={AbrirModal}>Cancelar</Button>,
          <Button type="primary" onClick={() => ControlOperaciones(LabelModal)}>
            {LabelModal}
          </Button>,
        ]}
      >
        <Form {...layout}>
          <Item label="Id" hidden>
            <Input
              name="id"
              onChange={handleChange}
              value={usuario && usuario.id}
            />
          </Item>

          <Item label="Título" >
            <Input
              name="Titulo"
              onChange={handleChange}
              value={usuario && usuario.Titulo}
            />
          </Item>

          <Item label="Descripción" >
            <TextArea
              name="Descripcion"
              onChange={handleChange}
              value={usuario && usuario.Descripcion}
            />
          </Item>

          <Item label="Url" >
            <Input
              name="Url"
              onChange={handleChangeUrl}
              value={usuario && usuario.Url}
            />
          </Item>
          <Item label="Preview" >
                {
                    usuario.Url && (
                        <div dangerouslySetInnerHTML={{__html: usuario.Url}}></div>
                    )
                }
                
          </Item>
         

        </Form>
      </Modal>
    </div>
  );
};

export default ServiciosPage;

const useStyles = makeStyles((theme) => ({
  Contenedor:{
    height:'100vh' , 
    paddingLeft:'110px' , 
    paddingRight:'110px'
  },
  table: {
    width: '100%',
    margin: '0 auto'
  },
  BtnIngresar: {
    margin: "10px 0px 20px 0px",
    whiteSpace: "nowrap"
  },
  "@media (max-width: 750px)": {
    Contenedor:{
      height:'100vh' , 
      paddingLeft:'10px' , 
      paddingRight:'10px'
    }
  }
}));
